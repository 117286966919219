<template>
  <div class="col-xl-9 col-lg-12 col-md-12">
    <div class="card mb-0">
      <div class="card-header">
        <router-link to="/profile/education" type="button">
          <i class="dropdown-icon si si-arrow-left"></i>
        </router-link>
        <h3 class="card-title">Katılımcı Listesi</h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 col-md-12">
            <div class="card col-sm-12">
              <div
                class="card-header"
                style="border: none"
                v-for="(item, i) in participantList"
                :key="i"
              >
                <h5 class="card-title">{{ item.fullname }}</h5>
                <div class="card-options">
                  <router-link
                    title="Mesaj Gönder"
                    :to="
                      '/profile/education/participant/' +
                      item.education_id +
                      '/' +
                      item.user_id +
                      '/message'
                    "
                  >
                    <i class="fe fe-message-square"></i>
                    <small class="badge badge-danger count" v-if="item.count>0">{{item.count}}</small>
                  </router-link>
                  <!--<a
                    title="Onayla"
                    v-if="item.type == 0"
                    @click="approvedItem(item.id)"
                    ><i class="fe fe-check"></i
                  ></a>-->
                  <a
                    title="Makbuz"
                    v-if="item.receipt!=''"
                    target="_blank"
                    :href="'https://storage.terapivitrini.com/'+item.receipt"
                    ><i class="fe fe-file"></i
                  ></a>
                  <a title="Sil" @click="removeItem(item.id)"
                    ><i class="fe fe-trash-2"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.count{
    font-size: 12px;
    position: absolute;
    margin-left: -30px;
    margin-top: -7px;
    border-radius: 15px;
    height: 20px;
    width: 20px;
    line-height: 13px;

}
</style>
  <script>
import Swal from "sweetalert2";
export default {
  created() {
    document.title = "Katılımcı Listesi";
    if(this.$store.state.user_type!='EXPERT' && this.$store.state.trainer!=1){
      this.$router.push("/profile");
    }
    this.$store
      .dispatch("approvedParticipantList", { id: this.$route.params.id })
      .then((value) => {
        this.participantList = value;
      });
  },
  data() {
    return {
      participantList: [],
    };
  },
  methods: {
    showReceipts(item){
      alert(item)
    },
    removeItem(id) {
      Swal.fire({
        title: "Katılımcıyı silmek istediğine emin misin?",
        text: "Bu işlem sonrası seçilen katılımcı silinecek, devam etmek istiyor musun?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Devam",
        cancelButtonText: "Vazgeç",
      }).then((result) => {
        if (result.isConfirmed) {
          let variable = {
            id: id,
          };
          this.$store.dispatch("partipicantRemove", variable).then((value) => {
            if (value.type == "error") {
              if (value.message == "ERRxUAR") {
                this.$vToastify.warning(
                  "Onay sürecindeki profil güncellenemez",
                  "Uyarı!"
                );
              }
            } else {
              this.$vToastify.success(
                "Katılımcı başarılı bir şekilde silindi",
                "Başarılı!"
              );
              this.$store
                .dispatch("approvedParticipantList", {
                  id: this.$route.params.id,
                })
                .then((value) => {
                  this.participantList = value;
                });
            }
          });
        }
      });
    },
    approvedItem(id) {
      Swal.fire({
        title: "Katılımcıyı onaylamak istediğinize emin misin?",
        text: "Bu işlem katılımcıyı onaylar ve katılımcı için eğitim sürecini başlatır, devam etmek istiyor musun?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Devam",
        cancelButtonText: "Vazgeç",
      }).then((result) => {
        if (result.isConfirmed) {
          let variable = {
            id: id,
          };
          this.$store
            .dispatch("partipicantApproved", variable)
            .then((value) => {
              if (value.type == "error") {
                if (value.message == "ERRxUAR") {
                  this.$vToastify.warning(
                    "Onay sürecindeki profil güncellenemez",
                    "Uyarı!"
                  );
                }
              } else {
                this.$vToastify.success(
                  "Katılımcı başarılı bir şekilde onaylandı",
                  "Başarılı!"
                );
                this.$store
                  .dispatch("approvedParticipantList", {
                    id: this.$route.params.id,
                  })
                  .then((value) => {
                    this.participantList = value;
                  });
              }
            });
        }
      });
    },
  },
  components: {},
  mounted() {},
};
</script>